import { IonCard, IonCol, IonItem, IonLabel, IonRow, IonSearchbar, IonToggle } from '@ionic/react';
import { ChipAccount } from 'src/components/buyer/ChipAccount';
import { Breadcrumb, Breadcrumbs } from 'src/components/shared/Breadcrumbs';
import { ItemDescriptionCol } from 'src/components/shared/ItemDescriptionCol';
import { LogoSupplier } from 'src/components/shared/LogoSupplier';
import { ButtonAddToCart } from 'src/components/buyer/ButtonAddToCart';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { Account } from 'src/interfaces/Account';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import { ItemCatalog } from 'src/interfaces/ItemCatalog';
import { ItemPurchaseOrder } from 'src/interfaces/ItemPurchaseOrder';
import { ItemShoppingCart } from 'src/interfaces/ItemShoppingCart';
import { Location } from 'src/interfaces/Location';
import { Supplier } from 'src/interfaces/Supplier';
import { Page } from 'src/layouts/Page';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setMedicationSearch } from 'src/redux/appReducer';
import { DataTablePagination } from 'src/components/shared/DataTable/interfaces/DataTablePagination';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { hideOutOfStock } from 'src/utils/formatters';

export const ItemsPage: React.FC = () => {
  const api = useApi();
  const utils = useUtils();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const dispatch = useDispatch();

  const [selectedSupplier, setSelectedSupplier] = useState<Supplier>();

  const location = useLocation();
  const [search, setSearch] = useState<string | null>();

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<ItemCatalog[]>([]);

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [outOfStock, setOutOfStock] = useState<boolean>(true);

  const [pagination, setPagination] = useState<DataTablePagination | null>(
    null
  );
  const [totalCount, setTotalCount] = useState<number>(0);

  const [selectedItem, setSelectedItem] = useState<
    ItemCatalog | ItemShoppingCart | ItemPurchaseOrder | null
  >(null);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Items' },
  ];

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    getAccounts();
    if (selectedSupplier) {
      params.append('supplier_id', `${selectedSupplier.supplier_id}`);
    }
  }, [selectedLocation, selectedSupplier]);

  useEffect(() => {
    setSearch(params.get('search') || '');
    dispatch(setMedicationSearch(null));
  }, [location.search]);

  useEffect(() => {
    getItems();
  }, [search, pagination, selectedLocation, outOfStock]);

  const headers: Array<DataTableHeader> = [
    { text: 'Item', key: 'item' },
    { text: 'Account', key: 'account' },
    { text: 'Price', key: 'packPrice', align: 'right' },
    { text: 'Quantity', key: 'actions', align: 'left', width: '325px' },
  ];

  const getAccounts = () => {
    if (!selectedLocation) return;
    setAccountsLoading(true);
    api
      .get(`location/${selectedLocation.location_id}/accounts`)
      .then(({ data }) => {
        setAccounts(data);
        setAccountsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAccountsLoading(false);
      });
  };

  const getItems = () => {
    if (!selectedLocation || !pagination) return;
    setLoading(true);
    api
      .get(`location/${selectedLocation.location_id}/catalog`, {
        limit: pagination.limit,
        offset: pagination.offset,
        search: search || undefined,
      })
      .then((response) => {
        if (response.status === 200) {
          if(outOfStock){
            // this works only when "on_hand_quantity" or "on_hand_status" is null or not in datas set and also when value is other then zero
            const itemsWithoutOutOfStock = hideOutOfStock(response.data.result)
            setItems(itemsWithoutOutOfStock || []);
          }else{
            setItems(response.data.result || []);
          }
          setTotalCount(response.data.total);
        }
        setLoading(false);
      });
  };

  const clearFilters = () => {
    setSearch(null);
  };

  return (
    <Page title="Search Results">
      <Breadcrumbs breadcrumbs={breadcrumbs} title="Search Results" />
      <IonRow>
        <IonCol size="12">
          <IonCard>
            <DataTable
              headers={headers}
              search={false}
              loading={loading}
              title="All Items"
              onPaginationChange={(pagination: DataTablePagination) =>
                setPagination(pagination)
              }
              itemsPerPage={50}
              totalCount={totalCount}
              subtitle={
                  selectedSupplier && (
                    <LogoSupplier
                      src={selectedSupplier.logo}
                      alt={selectedSupplier.name}
                      title={selectedSupplier.name}
                      style={{ objectPosition: 'left' }}
                    />
                  )
              }
              rows={items.map((item): DataTableRow => {
                const selectedAccount = accounts.find(
                  (account) => account.number === item.account_number
                );
                const row: DataTableRow = {
                  item: item,
                  key: `${item.supplier_item_number}-${item.account_number}`,
                  columns: [
                    {
                      header: 'item',
                      content: (
                        <ItemDescriptionCol
                          item={item}
                          account={selectedAccount}
                        />
                      ),
                    },
                    {
                      header: 'account',
                      content: (
                        <>
                          {selectedAccount ? (
                            <ChipAccount
                              account={selectedAccount}
                              style={{ margin: 0 }}
                            />
                          ) : null}
                        </>
                      ),
                    },
                    {
                      header: 'packPrice',
                      content: (
                        <p className="font-weight-500 text-color-black">
                          {item.item_unit_price
                            ? utils.toUsd(
                                parseFloat(item.item_unit_price || '0')
                              )
                            : 'N/A'}
                        </p>
                      ),
                    },
                    {
                      header: 'actions',
                      content: (
                        <>
                          {selectedAccount &&
                          authUser &&
                          !utils.isAnalyst(authUser) ? (
                            <ButtonAddToCart
                              item={item}
                              account={selectedAccount}
                            />
                          ) : null}
                        </>
                      ),
                    },
                  ],
                };
                return row;
              })}
              children={              
              <IonRow className="ion-text-left">
                <IonCol size="12" sizeSm="5"  sizeLg='4'>
                  <IonItem lines="none">
                    <span 
                      style={{paddingRight: "10px"}}
                      data-tooltip-id="global-tooltip"
                      data-tooltip-place="top"
                      data-tooltip-content="This feature only works with suppliers who provide on hand stock quantities">
                        Hide out of stock
                    </span>
                    <IonToggle checked={outOfStock} onIonChange={() => setOutOfStock(!outOfStock)}></IonToggle>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeSm="7" sizeLg='8'>
                  <IonSearchbar
                    value={search}
                    onIonChange={(e: any) =>
                      setSearch(e.detail.value as string)
                    }
                    debounce={350}
                  />
                </IonCol>
              </IonRow>}
            />
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
